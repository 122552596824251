<template>
  <div id="app" v-show="visible" v-if="gotConfig"
    v-bind:class="{ secundaryPage: isSecondaryPage, ['language-'+language]:true }">
    <WaitingForServer></WaitingForServer>
    <GlobalMessage></GlobalMessage>
    <div class="main" v-bind:class="{isModule: (showHeader === false && showFooter === false)}">
      <header-block v-if="showHeader !== false"></header-block>
      <CookieDisclaimer v-if="cookieDisclaimerIsVisible"></CookieDisclaimer>
      <router-view class="container-fluid"></router-view>
    </div>
    <footer-block v-if="showFooter !== false"></footer-block>
    <div class="debugging" v-if="env === 'development'">{{sizeName}} ({{branch}})
      <div class="extra">
        {{hash}} ({{formatedDate}})
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WaitingForServer from './components/WaitingForServer.vue';
import Header from './components/Header/Header.vue';
import Footer from './components/Footer/Footer.vue';
import GlobalMessage from './components/GlobalMessage.vue';
import CookieDisclaimer from './components/CookieDisclaimer/CookieDisclaimer.vue';
// import ErrorHandler from './components/ErrorHandler.vue';

export default {
  name: 'app',
  components: {
    HeaderBlock: Header,
    FooterBlock: Footer,
    WaitingForServer,
    GlobalMessage,
    // ErrorHandler,
    CookieDisclaimer,
  },
  data() {
    return {
      visible: false,
      gotConfig: false,
      env: import.meta.env.VITE_NODE_ENV,
      timestamp: import.meta.env.VITE_TIMESTAMP,
      hash: import.meta.env.VITE_HASH,
      branch: import.meta.env.VITE_BRANCH,
      sizeName: 'screenXs',
    };
  },
  computed: {
    ...mapState({
      cookieDisclaimerIsVisible: (state) => state.cookieDisclaimer.isVisible,
      showHeader: (state) => state.app.showHeader,
      showFooter: (state) => state.app.showFooter,
      language: (state) => state.language,
    }),
    formatedDate() {
      const d = new Date(this.timestamp);
      return d.toISOString();
    },
    isSecondaryPage() {
      if (
        this.$route.name === 'login'
        || this.$route.name === 'register'
        || this.$route.name === 'guestRegister'
        || this.$route.name === 'settings'
        || this.$route.name === 'forgotPassword'
        || this.$route.name === 'recoverSuccess'
        || this.$route.name === 'recoverAccount'
        || this.$route.name === 'forgotPasswordSuccess'
        || this.$route.name === 'recoverPasswordSuccess'
        || this.$route.name === 'registerSuccess'
        || this.$route.name === 'checkoutSuccess'
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.getRemoteConfig().then(() => {
      this.gotConfig = true;
      this.setSavedJWT();
      this.setSavedCustomer();
      this.getPaymentTypes();
      this.getMeta();
    });
  },
  mounted() {
    window.shopTime = this.timestamp;
    window.shopHash = this.hash;
    const s = this;
    function sizeClass() { // currenty only used to show breakpoints
      s.sizeName = 'screenXs';
      document.querySelector('body').classList.add('screenXs');
      document.querySelector('body').classList.remove('screenSm');
      document.querySelector('body').classList.remove('screenMd');
      document.querySelector('body').classList.remove('screenLg');
      document.querySelector('body').classList.remove('screenXl');

      if (window.innerWidth > 544) {
        s.sizeName = 'screenSm';
        document.querySelector('body').classList.remove('screenXs');
        document.querySelector('body').classList.add('screenSm');
        document.querySelector('body').classList.remove('screenMd');
        document.querySelector('body').classList.remove('screenLg');
        document.querySelector('body').classList.remove('screenXl');
      }

      if (window.innerWidth > 768) {
        s.sizeName = 'screenMd';
        document.querySelector('body').classList.remove('screenXs');
        document.querySelector('body').classList.remove('screenSm');
        document.querySelector('body').classList.add('screenMd');
        document.querySelector('body').classList.remove('screenLg');
        document.querySelector('body').classList.remove('screenXl');
      }

      if (window.innerWidth > 992) {
        s.sizeName = 'screenLg';
        document.querySelector('body').classList.remove('screenXs');
        document.querySelector('body').classList.remove('screenSm');
        document.querySelector('body').classList.remove('screenMd');
        document.querySelector('body').classList.add('screenLg');
        document.querySelector('body').classList.remove('screenXl');
      }

      if (window.innerWidth > 1200) {
        s.sizeName = 'screenXl';
        document.querySelector('body').classList.remove('screenXs');
        document.querySelector('body').classList.remove('screenSm');
        document.querySelector('body').classList.remove('screenMd');
        document.querySelector('body').classList.remove('screenLg');
        document.querySelector('body').classList.add('screenXl');
      }
      s.sizeName += ` (${window.innerWidth} × ${window.innerHeight}) `;
    }
    if (import.meta.env.VITE_NODE_ENV === 'development') {
      window.onresize = () => {
        sizeClass();
      };
      sizeClass();
      // Show that there was an error
    }
    this.visible = true
    /*eslint-disable */
    if (import.meta.env.VITE_NODE_ENV === 'development') {
    const log = document.querySelector('#debug');
    ['error'].forEach((verb) => {
      console[verb] = (function (method, verb, log) {
        return function () {
          method.apply(console, arguments);
          const msg = `${Array.prototype.slice.call(arguments).join(' ')}`;
          window.vue.$toasted[verb](msg).goAway(3000);
        };
      }(console[verb], verb, log));
    });
    }
    /* eslint-enable */
  },
  methods: {
    ...mapActions({
      setSavedJWT: 'user/setSavedToken',
      setSavedCustomer: 'customer/setSavedCustomer',
      getPaymentTypes: 'payments/get',
      getRemoteConfig: 'getRemoteConfig',
      getMeta: 'meta/get',
    }),
  },
};
</script>

<style lang="scss" scoped>
.main:not(.isModule) {
  min-height: calc(100vh - 170px);
}
.secundaryPage {
  background: $secondary-page-background-color;
}

</style>
<style lang="scss">
@import './scss/main.scss';
.debugging {
  font-size: 12px;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: black;
  color: white;
  padding: 6px;
  .extra {
    display: none;
  }
}
.debugging:hover .extra {
  display: block;
}
body {
  overflow-x: hidden;
}
</style>
